import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, newOrder } from "../api";
import logo from "../img/logo.png";

const Auth = () => {
  const nav = useNavigate();
  const [data, setData] = useState({
    login: "",
    password: "",
  });

  useEffect(() => {
    if (localStorage.getItem("login")) {
      nav("/");
      return;
    }
  }, []);
  const login = async () => {
    localStorage.setItem("login", data.login)
    localStorage.setItem("password", data.password)
    const user = await getUser()

    if (user) {
        nav("/")
    } else {
        localStorage.removeItem("login")
        localStorage.removeItem("password")
        window.location.reload()
    }
  };

  return (
    <>
      <div class="container-order-data">
        <div class="order-form order-form-auth">
         <div style={{
           width: "100%",
              display: "flex",
                justifyContent: "center",
                    alignItems: "center",
         }}>
           <img src={logo} width={200} />
         </div>
          <p class="order-data-page-title">Авторизация</p>

          <div class="form-group-section">
            <div class="form-group">
              <input type="text" class="formInput" placeholder="Почта" value={data.login} onChange={(e) => {
                setData({
                    ...data,
                    login: e.target.value
                })
              }} />
            </div>
            <div class="form-group">
              <input type="text" class="formInput" placeholder="Пароль" value={data.password} onChange={(e) => {
                setData({
                    ...data,
                    password: e.target.value
                })
              }} />
            </div>
          </div>
          <div className="button" onClick={login}>
            Войти
          </div>

          <div class="delivery-description text-red"></div>
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",  
          }}>
            <p style={{
              color: "rgba(255, 255, 255, 0.5)",
            }}>
              Мы выбрали
              {' '}
              <a href="https://spruton.shop/" target="_blank" rel="noreferrer">СПРУТОН МАРКЕТ</a>
              {' '}
              для создания магазина
            </p>
            <p>
              Техподдержка: <a href="mailto:support@spruton.shop">support@spruton.shop</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
