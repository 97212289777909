import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, newOrder } from "../api";
import arrowIcon from "../img/arrow-left.svg"

const Contact = () => {
  const nav = useNavigate();
  
  return (
    <>
      <div class="container-order-data">
        <div class="order-form">
            
          <p class="order-data-page-title"> <img src={arrowIcon} onClick={() => {
                nav("/")
            }} />Контакты</p>

          <p>По всем вопросам обращаться по телефону: <a href={"tel:+79786121068"}>+79786121068</a></p>
        </div>
      </div>
    </>
  );
};

export default Contact;
