const getProducts = async () => {
  const login = localStorage.getItem("login");
  const password = localStorage.getItem("password");

  const req = await fetch("https://api-site.toyseller.site/api/products", {
    headers: {
      authorization: `${login}_-_${password}`,
    },
  });
  const res = await req.json();
  console.log(res.data)

  return res.data;
};

const getUser = async () => {
  const login = localStorage.getItem("login");
  const password = localStorage.getItem("password");

  const req = await fetch(
    "https://api-site.toyseller.site/api/user", 
    {
      headers: {
        authorization: `${login}_-_${password}`,
      },
    }
  );
  const res = await req.json();

  return res.data;
};

const newOrder = async (data) => {
  const login = localStorage.getItem("login");
  const password = localStorage.getItem("password");

  const req = await fetch("https://api-site.toyseller.site/api/order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${login}_-_${password}`,
    },
    body: JSON.stringify({
      ...data
    }),
  });
  const res = await req.json();

  return res.data;
};

export { getUser, getProducts, newOrder };
