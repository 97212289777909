import { YMInitializer } from "react-yandex-metrika";
import App from "./App";

export const AppWrapper = ({ children }) => {
  return (
    <>
      <App />
      <YMInitializer accounts={[97574006]} />
    </>
  );
};
